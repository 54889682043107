'use strict'

# フリックかタッチか判別
touchPos = 0
threshold = 10
isFlick = false

$('.js-accordion__button').on('touchstart',
  (e)->
    isFlick = false
    touchPos = e.touches[0].clientY
)

document.addEventListener('touchmove',
  (e)->
    currentPos = e.touches[0].clientY

    if Math.abs(touchPos - currentPos) > threshold
      isFlick = true
    else
      isFlick = false
, {passive: true})

# アコーディオン 変更監視
observer = new MutationObserver((e)->
  $target = $(e[0].target)

  if $target.get(0).classList.value.indexOf('is-open-initially') > -1
    console.log('accordion')
    $target.addClass('is-open')

    parentContentsHeight = $target.find('> .js-accordion__contents > .js-accordion__contents__inner').outerHeight()

    $target.find('> .js-accordion__contents').css({
      'height': parentContentsHeight
      'maxHeight': parentContentsHeight
    })

    $target.removeClass('is-open-initially')
)

elem = document.querySelectorAll('.js-accordion')

config = {
  attributes: true,
  attributeFilter: ['class']
}

Array.prototype.forEach.call(elem, (elem)->
  observer.observe(elem, config)
)

# アコーディオン クリック
$('.js-accordion__button').on('click',
  (e, val1)->
    $parent = $root = $(this).closest('.js-accordion')

    if not isFlick or $parent.hasClass('is-open-initially') or val1 is 'trigger'
      $parent.removeClass('is-close')
      $parent.toggleClass('is-open')
      parentContentsHeight = rootContentsHeight = $parent.find('> .js-accordion__contents > .js-accordion__contents__inner').outerHeight()

      if $parent.hasClass('is-open')
        $parent.find('> .js-accordion__contents').css({
          'height': parentContentsHeight
          'maxHeight': parentContentsHeight
        })
      else
        $parent.find('> .js-accordion__contents').removeAttr('style')

      if $parent.hasClass('is-open-all')
        $parent.toggleClass('is-open-all')
        $parent.addClass('is-close-all')
      else if $parent.hasClass('is-close-all')
        $parent.toggleClass('is-close-all')
        $parent.addClass('is-open-all')

      if $parent.hasClass('js-subaccordion')
        $root = $parent.parents('.js-accordion')
        rootContentsHeight = $root.find('> .js-accordion__contents > .js-accordion__contents__inner').outerHeight()

        if $root.hasClass('is-open') and $parent.hasClass('is-open')
          $root.addClass('is-open-all')
        else
          $root.removeClass('is-open-all')

        if $root.hasClass('is-open')
          if $root.hasClass('is-open-all')
            allContentsHeight = if parentContentsHeight isnt rootContentsHeight then rootContentsHeight + parentContentsHeight else parentContentsHeight
          else
            allContentsHeight = if parentContentsHeight isnt rootContentsHeight then rootContentsHeight - parentContentsHeight else parentContentsHeight

          $root.find('> .js-accordion__contents').removeAttr('style')
          $root.find('> .js-accordion__contents').css({
            'height': allContentsHeight
            'maxHeight': allContentsHeight
          })

      $parent.on('oTransitionEnd mozTransitionEnd webkitTransitionEnd transitionend',
        ()->
          if not $parent.hasClass('is-open')
            $parent.addClass('is-close')
      )

      $parent.removeClass('is-open-initially')
)